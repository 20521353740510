import React, { useContext, useEffect, useState } from "react";
import {
  LensTechnogolyData,
  LensCategoryItem,
} from "../../utils/commonInterfaces";
import { SECTION_HEADINGS } from "../../utils/constants";
import { lensCategories } from "../../utils/lensCategories";
import MenuTransitions from "../../widgets/MenuTransitions";
import { LensCategoryContext } from "../../context/selectedLensCategory";
import { useTranslation } from 'react-i18next';

export interface LeftPanelMaterialData {
  isFullWidth: boolean;
}

const LensParameters: React.FC<LeftPanelMaterialData> = (props:any) => {
  const {isFullWidth} = props;
  const lensCategoryContext = useContext(LensCategoryContext);
  const { t } = useTranslation()
  const [openMenu, setOpenMenu] = useState<string | null>("singleVision");
  const [biFocalCategory, setBiFocalCategory] = useState<LensTechnogolyData[]>(
    []
  );
  const [SingleVisionCategory, setSingleVisionCategory] = useState<
    LensTechnogolyData[]
  >([]);

  const [officeProCategory, setOfficeProCategory] = useState<
    LensTechnogolyData[]
  >([]);
  const [progressiveProCategory, setProgressiveProCategory] = useState<
    LensTechnogolyData[]
  >([]);

  useEffect(()=>{
    lensCategoryContext.setSelectedCategory("singleVision");
  },[])


  const handleMenuToggle = (menuKey: string) => {
    setOpenMenu((prevMenu) => (prevMenu === menuKey ? null : menuKey));
    lensCategoryContext.setSelectedCategory(menuKey);
  };
  useEffect(() => {
    const categories = lensCategories.reduce<{
      singleVision: LensCategoryItem[];
      biFocal: LensCategoryItem[];
      officePro: LensCategoryItem[];
      progressive: LensCategoryItem[];
    }>(
      (acc, data) => {
        if (data.key === "singleVision") acc.singleVision.push(data);
        if (data.key === "biFocal") acc.biFocal.push(data);
        if (data.key === "officePro") acc.officePro.push(data);
        if (data.key === "progressive") acc.progressive.push(data);
        return acc;
      },
      { singleVision: [], biFocal: [], officePro: [], progressive: [] }
    );
    setSingleVisionCategory(categories.singleVision);
    setBiFocalCategory(categories.biFocal);
    setOfficeProCategory(categories.officePro);
    setProgressiveProCategory(categories.progressive);
  }, []);

  return (
    <div className="parameter-selector" style={{width:isFullWidth?"100%":"auto"}}>
      <div className="parameter-selector__scroll">
        <MenuTransitions
          title={t('SECTION_HEADINGS.CATEGORY_SINGLE_VISION')}
          paramItems={SingleVisionCategory}
          deafultOpenMenu={openMenu === "singleVision"}
          onMenuToggle={() => handleMenuToggle("singleVision")}
        />
        <MenuTransitions
          title={t('SECTION_HEADINGS.CATEGORY_BI_FOCAL')}
          paramItems={biFocalCategory}
          deafultOpenMenu={openMenu === "biFocal"}
          onMenuToggle={() => handleMenuToggle("biFocal")}
        />
        <MenuTransitions
          title={t('SECTION_HEADINGS.CATEGORY_OFFICE_PRO')}
          paramItems={officeProCategory}
          deafultOpenMenu={openMenu === "officePro"}
          onMenuToggle={() => handleMenuToggle("officePro")}
        />
        <MenuTransitions
          title={t('SECTION_HEADINGS.CATEGORY_PROGRESSIVE')}
          paramItems={progressiveProCategory}
          deafultOpenMenu={openMenu === "progressive"}
          onMenuToggle={() => handleMenuToggle("progressive")}
        />
      </div>
    </div>
  );
};

export default LensParameters;
