import { LensCategoryItem } from "./commonInterfaces";
import { bifocalKTypeData } from "./kBiFocalData";
import { neoBinocsData } from "./neoBinocsData";
import { neoDigiData } from "./neoDigiData";
import { neoErgoData } from "./neoErgoData";
import { neoProData } from "./neoProData";
import { neoSpaceData } from "./neoSpaceData";
import { neoSyncData } from "./neoSyncData";
import { neoUnoData } from "./neoUnoData";
import { ultimaData } from "./ultimaData";
import { initiaData } from "./initiaData";
import { neoExpertData } from "./neoExpert";
import { drivEZData } from "./drivEZ";
import { aceBiFocalData } from "./aceBiFocalData";
import { dBiFocalData } from "./dBiFocalData";
import { officeProMeeting } from "./officeProMeeting";
import { officeProDesktop } from "./officeProDesktop";
import { officeProLaptop } from "./officeProLaptop";
import { bifocalKDitalData } from "./bifocalKDigitalData";
import { singleVisionData } from "./singleVisionData";
import { genXTData } from "./genXTData";
import i18next from '../i18n';
const getTranslation = (key: string) => i18next.t(key);


export const lensCategories: LensCategoryItem[] = [
  {
    key: "singleVision",
    value: "singleVisionRx",
    displayName: "Single Vision",
    // get displayName() {
    //   return getTranslation('LensCategories.SingleVision'); // Always fetch latest
    // },
    data: singleVisionData,
  },
  {
    key: "singleVision",
    value: "genXT",
    displayName: "GenXT",
    // get displayName() {
    //   return getTranslation('LensCategories.GenXT'); // Always fetch latest
    // },
    data: genXTData,
  },
  {
    key: "singleVision",
    value: "drivEZ",
    displayName: "DrivEZ",
    // get displayName() {
    //   return getTranslation('LensCategories.DrivEZ'); // Always fetch latest
    // },
    data: drivEZData,
  },
  {
    key: "biFocal",
    value: "dBiFocal",
    displayName: "D Bi Focal",
    // get displayName() {
    //   return getTranslation('LensCategories.DBiFocal'); // Always fetch latest
    // },
    data: dBiFocalData,
  },
  {
    key: "biFocal",
    value: "kBiFocal",
    displayName: "K Bi Focal",
    // get displayName() {
    //   return getTranslation('LensCategories.KBiFocal'); // Always fetch latest
    // },
    data: bifocalKTypeData,
  },
  // {
  //   key: "biFocal",
  //   value: "kBiFocalDigital",
  //   displayName: "K Bi Focal (Digital)",
  //   data: bifocalKDitalData,
  // },
  // {
  //   key: "biFocal",
  //   value: "aceBiFocal",
  //   displayName: "Ace Bi Focal",
  //   data: aceBiFocalData,
  // },
  {
    key: "officePro",
    value: "officeProLaptop",
    displayName: "Laptop",
    // get displayName() {
    //   return getTranslation('LensCategories.Laptop'); // Always fetch latest
    // },
    data: officeProLaptop,
  },
  {
    key: "officePro",
    value: "officeProDesktop",
    displayName: "Desktop",
    // get displayName() {
    //   return getTranslation('LensCategories.Desktop'); // Always fetch latest
    // },
    data: officeProDesktop,
  },
  // {
  //   key: "officePro",
  //   value: "officeProMeeting",
  //   displayName: "Meeting",
  //   // get displayName() {
  //   //   return getTranslation('LensCategories.Meeting'); // Always fetch latest
  //   // },
  //   data: officeProMeeting,
  // },

  {
    key: "progressive",
    value: "initia",
    displayName: "Initia",
    // get displayName() {
    //   return getTranslation('LensCategories.Initia'); // Always fetch latest
    // },
    data: initiaData,
  },
  {
    key: "progressive",
    value: "neoDigi",
    displayName: "Neo Digi",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoDigi'); // Always fetch latest
    // },
    data: neoDigiData,
  },
  {
    key: "progressive",
    value: "neoUno",
    displayName: "Neo Uno",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoUno'); // Always fetch latest
    // },
    data: neoUnoData,
  },
  {
    key: "progressive",
    value: "neoPro",
    displayName: "Neo Pro",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoPro'); // Always fetch latest
    // },
    data: neoProData,
  },
  {
    key: "progressive",
    value: "neoExpert",
    displayName: "Neo Expert",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoExpert'); // Always fetch latest
    // },
    data: neoExpertData,
  },
  {
    key: "progressive",
    value: "drivez",
    displayName: "DrivEZ",
    // get displayName() {
    //   return getTranslation('LensCategories.DrivEZ'); // Always fetch latest
    // },
    data: drivEZData,
  },
  {
    key: "progressive",
    value: "neoSpace",
    displayName: "Neo Space",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoSpace'); // Always fetch latest
    // },
    data: neoSpaceData,
  },
  {
    key: "progressive",
    value: "neoBinocs",
    displayName: "Neo Binocs",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoBinocs'); // Always fetch latest
    // },
    data: neoBinocsData,
  },
  {
    key: "progressive",
    value: "neoErgo",
    displayName: "Neo Ergo",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoErgo'); // Always fetch latest
    // },
    data: neoErgoData,
  },
  {
    key: "progressive",
    value: "neoSync",
    displayName: "Neo Sync",
    // get displayName() {
    //   return getTranslation('LensCategories.NeoSync'); // Always fetch latest
    // },
    data: neoSyncData,
  },
  {
    key: "progressive",
    value: "ultima",
    displayName: "Ultima",
    // get displayName() {
    //   return getTranslation('LensCategories.Ultima'); // Always fetch latest
    // },
    data: ultimaData,
  },
];
