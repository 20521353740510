import * as React from "react";
import { styled } from "@mui/system";
import { LensTechnogolyData } from "../../utils/commonInterfaces";
import { Chip } from "@mui/material";
import { useContext, useState } from "react";
import { CoatingContext } from "../../context/coatingContext";
import { MaterialContext } from "../../context/materialContext";
import { TreatmentContext } from "../../context/treatmentContext";
import ColorPicker from "../ColorPicker";
import { InfoAnimContext } from "../../context/infoAnimContext";
import {
  clearSiteAnimationData,
  HMCAnimationData,
  blueTechAnimationData,
  drivEZAnimationData,
  clearSightBestClarityData,
  clearSightZBestClarityData,
  clearSightHightstTransparencyData,
  clearSightZHightstTransparencyData,
} from "../../utils/clearSiteAnimationData";
import {
  treatmentAnimationData,
  TreatmentAnimation,
} from "../../utils/treatmentAnimationData";
import MaterialThicknessComparison from "../MaterialThicknessComparison";
import { TechContext } from "../../context/technologyContext";
import { useTranslation } from "react-i18next";
interface PoppersProps {
  title: string;
  paramItems: LensTechnogolyData[];
  setIsEnableColor?: (isEnableColor: boolean) => void;
}

const Poppers: React.FC<PoppersProps> = ({
  paramItems,
  title,
  setIsEnableColor,
}) => {
  const materialContext = useContext(MaterialContext);
  const coatingContext = useContext(CoatingContext);
  const treatmentContext = useContext(TreatmentContext);
  const infoAnimContext = useContext(InfoAnimContext);
  const techContext = useContext(TechContext);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isMaterialThicknessOpen, setIsMaterialThicknessOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string>("");
  const [isOpenChips, setIsOpenChips] = useState<boolean>(true);
  const [selectedParam, setselectedParam] = useState<string>("");
  const { t } = useTranslation();

  React.useEffect(() => {
    if (selectedParam === "mirror") {
      treatmentContext.setSelectedTreatment(selectedParam);
      setIsEnableColor?.(false);
      // setShowColorPicker(true);
      // if (techContext?.technology) {
      //   const updatedTechnology = techContext.technology.map((techItem) => {
      //     return { ...techItem, isLensColor: true };
      //   });
      //   techContext.setTechnology(updatedTechnology);
      // }
    }
  }, [selectedParam]);

  const handleClick = () => {
    setIsOpenChips(!isOpenChips);
    setShowColorPicker(!showColorPicker);
  };
  const handleItemChange = (item: LensTechnogolyData) => {
    setselectedParam(item.value);
    if (item.value !== "transitions") {
      if (item.key === "coating" && item.value !== "") {
        coatingContext.setSelectedCoating(item.value);
        if (item.value === "hmc") {
          infoAnimContext.setSelectedInfoAnim(HMCAnimationData);
        } else if (item.value === "blueTech+") {
          infoAnimContext.setSelectedInfoAnim([
            ...blueTechAnimationData,
            ...HMCAnimationData,
          ]);
        } else if (item.value === "clearSight") {
          infoAnimContext.setSelectedInfoAnim([
            ...clearSightBestClarityData,
            ...clearSightHightstTransparencyData,
            ...clearSiteAnimationData,
            ...blueTechAnimationData,
          ]);
        } else if (item.value === "clearSightZ") {
          infoAnimContext.setSelectedInfoAnim([
            ...clearSightZBestClarityData,
            ...clearSightZHightstTransparencyData,
            ...clearSiteAnimationData,
            ...blueTechAnimationData,
          ]);
        } else if (item.value === "drivez") {
          infoAnimContext.setSelectedInfoAnim([
            ...drivEZAnimationData,
            ...blueTechAnimationData,
            ...HMCAnimationData,
          ]);
        }
      } else if (item.key === "material" && item.value !== "") {
        materialContext.setSelectedMaterial(item.value);
        setIsMaterialThicknessOpen(!isMaterialThicknessOpen);
        if (!isMaterialThicknessOpen) {
          setselectedParam(item.value);
          setSelectedKey(item.value);
        } else {
          setselectedParam("");
        }
      } else if (item.key === "treatment" && item.value !== "") {
        if (item.value === "tinted" || item.value === "mirror") {
          treatmentContext.setSelectedTreatment(item.value);
          // if (setIsEnableColor) {
          //   setIsEnableColor(true);
          // }
        } else {
          if (setIsEnableColor) {
            setIsEnableColor(false);
          }
          const newTreatmentData = {
            benefits:
              treatmentAnimationData[item.value as keyof TreatmentAnimation]
                .benefits!,
            displayName:
              treatmentAnimationData[item.value as keyof TreatmentAnimation]
                .displayName!,
            animationSource:
              treatmentAnimationData[item.value as keyof TreatmentAnimation]
                .animationSource!,
            addOn: true,
            title: item.displayName!,
            backgroundImage: item.backgroundImg,
          };
          infoAnimContext.setSelectedInfoAnim([newTreatmentData]);
        }
      } else if (item.key === "color" && item.value !== "") {
        setShowColorPicker(true);
        if (techContext?.technology) {
          const updatedTechnology = techContext.technology.map((techItem) => {
            return { ...techItem, isLensColor: true };
          });
          techContext.setTechnology(updatedTechnology);
        }
      } else {
        setselectedParam("");
      }
    }
  };
  return (
    <div>
      <div className="poper-section">
        <TriggerButton
          aria-describedby="simple-popper"
          type="button"
          onClick={handleClick}
          className={`poper-section__popper-btn ${isOpenChips && "filled-bg"}`}
        >
          {title}
        </TriggerButton>
        <div className="poper-section__chips-wrapper">
          {isOpenChips &&
            paramItems.map(
              (param, index) =>
                param.value !== "" &&
                !(title === "Colors" && param.value === "mirror") && (
                  <Chip
                    key={index}
                    className={`poper-section__chips ${
                      selectedParam === param.value && "bg-secondry"
                    }`}
                    // label={param.displayName}
                    label={
                      param.key === "material"
                        ? param.displayName
                        : t(
                            `${param.key}.${param.displayName?.replace(
                              /\s+/g,
                              ""
                            )}`
                          )
                    }
                    variant="outlined"
                    onClick={() => handleItemChange(param)}
                  />
                )
            )}
        </div>
        {showColorPicker && <ColorPicker colors={selectedParam} />}
      </div>
      {isMaterialThicknessOpen && (
        <>
          <MaterialThicknessComparison
            keyName={selectedKey}
            setIsMaterialThicknessOpen={setIsMaterialThicknessOpen}
            isMaterialThicknessOpen={isMaterialThicknessOpen}
          />
        </>
      )}
    </div>
  );
};

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const TriggerButton = styled("button")(
  ({ theme }) => `
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${blue[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${blue[500]};
  box-shadow: 0 2px 1px ${
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : "rgba(45, 45, 60, 0.2)"
  }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: ${blue[500]};
    }
  }
`
);

export default Poppers;
