import * as React from "react";
import { LensTechnogolyData } from "../../utils/commonInterfaces";
import { TechContext } from "../../context/technologyContext";
import { useEffect, useState } from "react";
import AnimationDialog from "../../components/AnimationDialog";
import { InfoAnimation } from "../../context/infoAnimContext";
import { useTranslation } from "react-i18next";
interface MenuTransitionsProps {
  title: string;
  paramItems: LensTechnogolyData[];
  deafultOpenMenu: boolean;
  onMenuToggle: () => void;
}

const MenuTransitions: React.FC<MenuTransitionsProps> = ({
  paramItems,
  title,
  deafultOpenMenu,
  onMenuToggle,
}) => {
  const [selectedParam, setselectedParam] = useState<string>("");
  const [openAnimatnModel, setOpenAnimatnModel] = useState<boolean>(false);
  const [animationInfo, setAnimationInfo] = useState<InfoAnimation[]>([]);
  const techContext = React.useContext(TechContext);
  const { t } = useTranslation();

  const handleMenuItemClick = (item: LensTechnogolyData) => {
    if (techContext?.technology[0].displayName === "GenXT") {
      window.location.reload();
    }
    if (item.key === "officePro") {
      if (item.data) {
        const { benefits, displayName, animationSource, backgroundImg } =
          item.data[0];
        const newOfficeAnimationData = {
          benefits,
          displayName,
          animationSource,
          backgroundImg,
        };
        setAnimationInfo([newOfficeAnimationData]);
        setOpenAnimatnModel(true);
      }
      setselectedParam(item.value);
      techContext?.setTechnology(item.data!);
    } else {
      setselectedParam(item.value);
      if (
        item.key === "biFocal" ||
        item.key === "officePro" ||
        item.key === "progressive" ||
        item.key === "singleVision"
      ) {
        techContext?.setTechnology(item.data!);
      }
    }
  };

  useEffect(() => {
    setselectedParam("singleVisionRx");
  }, []);

  return (
    <div className="menu-wrapper">
      <div className="menu-wrapper__category-dropdown">
        <button
          className={`menu-wrapper__category-btn ${
            deafultOpenMenu && "menu-wrapper__cat-btn-active"
          }`}
          onClick={onMenuToggle}
        >
          {title}
        </button>
        <div>
          {deafultOpenMenu && (
            <ul className="menu-wrapper__menu">
              {paramItems.map(
                (param, index) =>
                  param.value !== "" && (
                    <div key={index}>
                      <li
                        className={`menu-wrapper__menu-item ${
                          selectedParam === param.value &&
                          "menu-wrapper__active-menuitem"
                        }`}
                        onClick={() => handleMenuItemClick(param)}
                      >
                        {t(
                          `LensCategories.${param?.displayName?.replace(
                            /\s+/g,
                            ""
                          )}`
                        )}
                      </li>
                    </div>
                  )
              )}
            </ul>
          )}
        </div>
      </div>

      <AnimationDialog
        animationInfo={animationInfo}
        isCompareTech={false}
        isDialogOpen={openAnimatnModel}
        setIsDialogOpen={() => setOpenAnimatnModel(false)}
      />
    </div>
  );
};

export default MenuTransitions;
