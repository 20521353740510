import Transition from "../assets/animations/transition.mp4";
import ClearSiteZ from "../assets/animations/best-clarity-z.mp4";
import Polarized from "../assets/animations/Polarised.mp4";
import Stabilization from "../assets/animations/stabilization.mp4";
import Hydrophobic from "../assets/animations/Hydrophobic.mp4";
import HighestTransparency from "../assets/animations/Highest-Transparency.mp4";
import Photochromatic from "../assets/animations/photochromatic.mp4";
import RriveZAnimationVideo from "../assets/animations/DriveZ_technology.mp4";
import SunsafeAnimation from "../assets/animations/Sunsafe.mp4";
import photochromaticImg from "../assets/images/photochromatic.png";
import polarizedImg from "../assets/images/polarized.png";
import sunSafeImg from "../assets/images/sunsafe.png";
import drivezImg from "../assets/images/drivez.png";

export interface Treatment {
  displayName: string;
  benefits?: string[];
  animationSource: string;
  backgroundImage?:string;
}

export interface TreatmentAnimation {
  // clear: Treatment;
  tinted: Treatment;
  sunSafe: Treatment;
  // transitions: Treatment;
  polarized: Treatment;
  photoChromatic: Treatment;
  drivez: Treatment;
}

export const treatmentAnimationData: TreatmentAnimation = {
  // clear: {
  //   displayName: "Clear",
  //   benefits: [
  //     "Wider side-to-side visual fields",
  //     "Reduced distortions for clearer vision",
  //     "Enhanced comfort for your eyes",
  //     "Larger and more comfortable reading area",
  //     "Quicker and easier adjustment",
  //     "Clear vision in every direction you look",
  //     "Fully customized for each individual user",
  //   ],
  //   animationSource: ClearSiteZ,
  // },
  tinted: {
    displayName: "Tinted",
    benefits: [
      "Wider side-to-side visual fields",
      "Reduced distortions for clearer vision",
      "Enhanced comfort for your eyes",
      "Larger and more comfortable reading area",
      "Quicker and easier adjustment",
      "Clear vision in every direction you look",
      "Fully customized for each individual user",
    ],
    animationSource: "",
  },
  sunSafe: {
    displayName: "SunSafe",
    // benefits: [
    //   "Wider side-to-side visual fields",
    //   "Reduced distortions for clearer vision",
    //   "Enhanced comfort for your eyes",
    //   "Larger and more comfortable reading area",
    //   "Quicker and easier adjustment",
    //   "Clear vision in every direction you look",
    //   "Fully customized for each individual user",
    // ],
    animationSource: SunsafeAnimation,
    backgroundImage:sunSafeImg
  },
  // transitions: {
  //   displayName: "Transitions",
  //   // benefits: [
  //   //   "Wider side-to-side visual fields",
  //   //   "Reduced distortions for clearer vision",
  //   //   "Enhanced comfort for your eyes",
  //   //   "Larger and more comfortable reading area",
  //   //   "Quicker and easier adjustment",
  //   //   "Clear vision in every direction you look",
  //   //   "Fully customized for each individual user",
  //   // ],
  //   animationSource: Transition,
  // },
  polarized: {
    displayName: "Polarized",
    benefits: [
      "Wider side-to-side visual fields",
      "Reduced distortions for clearer vision",
      "Enhanced comfort for your eyes",
      "Larger and more comfortable reading area",
      "Quicker and easier adjustment",
      "Clear vision in every direction you look",
      "Fully customized for each individual user",
    ],
    animationSource: Polarized,
    backgroundImage:polarizedImg
  },
  photoChromatic: {
    displayName: "Photochromatic",
    // benefits: [
    //   "Wider side-to-side visual fields",
    //   "Reduced distortions for clearer vision",
    //   "Enhanced comfort for your eyes",
    //   "Larger and more comfortable reading area",
    //   "Quicker and easier adjustment",
    //   "Clear vision in every direction you look",
    //   "Fully customized for each individual user",
    // ],
    animationSource: Photochromatic,
    backgroundImage:photochromaticImg
  },
  drivez: {
    displayName: "Drivez",
    benefits: [
      "Wider side-to-side visual fields",
      "Reduced distortions for clearer vision",
      "Enhanced comfort for your eyes",
      "Larger and more comfortable reading area",
      "Quicker and easier adjustment",
      "Clear vision in every direction you look",
      "Fully customized for each individual user",
    ],
    animationSource: RriveZAnimationVideo,
    backgroundImage:drivezImg
  },
};
