import React, { createContext, useState, ReactNode } from "react";

interface CategoryContextType {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}

export const LensCategoryContext = createContext<CategoryContextType>({
  selectedCategory: "",
  setSelectedCategory: () => {},
});

interface CategoryProviderProps {
  children: ReactNode;
}

export const CategoryProvider: React.FC<CategoryProviderProps> = ({
  children,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  return (
    <LensCategoryContext.Provider value={{ selectedCategory, setSelectedCategory }}>
      {children}
    </LensCategoryContext.Provider>
  );
};
