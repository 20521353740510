import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation();

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = event.target.value;
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <select
      onChange={handleLanguageChange}
      value={i18n.language}
      className="navbar_language-select"
    >
      <option value="en">{t('LANGUAGES.English')}</option>
      <option value="hi">{t('LANGUAGES.Hindi')}</option>
      <option value="kn">{t('LANGUAGES.Kannada')}</option>
    </select>
  );
};

export default LanguageSelector;
