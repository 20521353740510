import React, { useState } from "react";
import TitanLogo from "../../assets/images/titan-logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { VscChromeClose } from "react-icons/vsc";
import SBICardBanner from "../../assets/images/titantitle.png";
import Button from "@mui/material/Button";
import { BUTTON_LABELS } from "../../utils/constants";
import CompareIcon from "../../assets/icons/Compare-Icon.svg";
import MobileBookTexture from "../../components/MobileBookTexture";
import { TechContext } from "../../context/technologyContext";
import LanguageSelector from "../../widgets/LanguageSelector";
import { useTranslation } from "react-i18next";
interface LensCategoriesProps {
  isCompareTech: boolean;
  setIsCompareTech: (isCompareTech: boolean) => void;
  activeENV: string;
  isBookObject: boolean;
  setIsBookObject: (isBookObject: boolean) => void;
  isMobileObject: boolean;
  setIsMobileObject: (isBookObject: boolean) => void;
  isPortraitMode?: boolean;
}

const LensCategories: React.FC<LensCategoriesProps> = ({
  isCompareTech,
  setIsCompareTech,
  activeENV,
  isBookObject,
  setIsBookObject,
  isMobileObject,
  setIsMobileObject,
  isPortraitMode,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const techContext = React.useContext(TechContext);
  const { t } = useTranslation();
  const compareTechnologies = () => {
    if (!isCompareTech) {
      if (techContext?.technology) {
        const updatedTechnology = techContext.technology.map((techItem) => {
          return { ...techItem, isLensColor: true };
        });
        techContext.setTechnology(updatedTechnology);
      }
      setTimeout(function () {
        setIsCompareTech(true);
      }, 400);
    } else {
      setIsCompareTech(false);
      window.location.reload();
    }
  };

  const openSbiCardPromotionLink = () => {
    window.open(
      "https://www.titanencircle.com/titansbicard?source=advertisement&brand=titan&campaign=campaign_12&medium=pr",
      "_blank"
    );
  };

  return (
    <div className="d-flex justify-content-between desktop-header header">
      {!isPortraitMode && (
        <div className="logo">
          <a href="/">
            <img src={TitanLogo} alt="TITAN EYE+" />
          </a>
        </div>
      )}

      {!isPortraitMode && (
        <div
          className="navbar__sbi-card-banner"
          onClick={openSbiCardPromotionLink}
        >
          <img
            src={SBICardBanner}
            height={57}
            alt="sbi-promotion-card-banner"
          />
        </div>
      )}
      <div className="d-flex flex-row navbar_btn-section">
        <LanguageSelector />
        {!isCompareTech && (
          <MobileBookTexture
            activeENV={activeENV}
            isBookObject={isBookObject}
            setIsBookObject={setIsBookObject}
            isMobileObject={isMobileObject}
            setIsMobileObject={setIsMobileObject}
          />
        )}
        <Button
          variant="contained"
          className="navbar__techcompare-btn"
          onClick={compareTechnologies}
        >
          <span className="navbar__techcompare-btn-content">
            <img src={CompareIcon} alt="CompareIcon" className="" />
            {isCompareTech
              ? t("BUTTON_LABELS.CANCEL_COMPARISON")
              : t("BUTTON_LABELS.COMPARE_WITH_OTHER_TECH")}
          </span>
        </Button>
      </div>

      <header className="header mobile-header">
        <nav className="navbar navbar-expand-lg">
          {!isPortraitMode && (
            <div className="navbar__menu-wrapper">
              {isMenuOpen === false && (
                <RxHamburgerMenu onClick={() => setIsMenuOpen(!isMenuOpen)} />
              )}
              {isMenuOpen === true && (
                <VscChromeClose onClick={() => setIsMenuOpen(!isMenuOpen)} />
              )}
            </div>
          )}

          {!isPortraitMode && (
            <div className="logo">
              <a href="/">
                <img src={TitanLogo} alt="TITAN EYE+" />
              </a>
            </div>
          )}
        </nav>
      </header>
    </div>
  );
};

export default LensCategories;
