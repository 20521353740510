import React, { useState, useRef, useEffect } from "react";
import { InfoAnimation } from "../../context/infoAnimContext";
import AccordionComponent from "../../widgets/Accordion";
import genXTAnimation from "../../assets/animations/GenXT.mp4";

interface InfoLayoutProps {
  animationInfo: InfoAnimation[];
  isCompareTech: boolean;
}

const InfoLayout: React.FC<InfoLayoutProps> = ({
  animationInfo,
  isCompareTech,
}) => {
  const [toggle, setToggle] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState(animationInfo[0]);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target as HTMLVideoElement;

          if (entry.isIntersecting) {
            const videoSrc = video.getAttribute("data-src");
            if (videoSrc && !video.src) {
              video.src = videoSrc;
            }
            if (video.paused && !video.ended) {
              video.play().catch((error) => {
                console.error("Error playing video:", error);
              });
            }
          } else {
            if (!video.paused) {
              video.pause();
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    videoRefs.current.forEach((videoRef) => {
      if (videoRef) {
        observer.observe(videoRef);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [animationInfo]);

  useEffect(() => {
    if (toggle) {
      setToggle(false);
    }
  }, [isCompareTech]);

  useEffect(() => {
    if (animationInfo[0].displayName === "GenXT") {
      setActiveAnimation(animationInfo[0]);
    }
  }, [animationInfo[0]]);

  return (
    <div className="info-layout-wrapper">
      <div className="info-layout info-layout_show">
        <div className={`info-layout__${"compare-disable"}`}>
          <div className="row info-layout_row m-1">
            {activeAnimation.animationSource && (
              <div className="col-12 info-layout_animation-area info-layout__half-video-layout">
                <video
                  ref={(el) => (videoRefs.current[0] = el)}
                  src={activeAnimation.animationSource}
                  style={{
                    width: "100%",
                  }}
                  muted
                  loop
                  controls
                  controlsList="nodownload noplaybackrate"
                  disablePictureInPicture
                  autoPlay
                />
              </div>
            )}
            <div className="col-12 info-layout_info-area info-layout__half-video-layout">
              <AccordionComponent benefits={activeAnimation?.benefits!} />
            </div>
          </div>
        </div>
      </div>
      {animationInfo.length > 1 && (
        <div className="main-footer_panoramas">
          {animationInfo.map((item, index) => (
            <button
              type="button"
              className={`main-footer_btn-env-list ${
                activeAnimation.displayName === item.displayName
                  ? "active-btn"
                  : ""
              }`}
              key={index}
              onClick={() => setActiveAnimation(item)}
              style={{
                backgroundImage: `url(${item.backgroundImage})`,
                backgroundSize: "cover",
              }}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
};

export default InfoLayout;
