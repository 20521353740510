import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import { TechProvider } from "./context/technologyContext";
import { CoatingProvider } from "./context/coatingContext";
import { MaterialProvider } from "./context/materialContext";
import { TreatmentProvider } from "./context/treatmentContext";
import { ColorProvider } from "./context/colorContext";
import { InfoAnimProvider } from "./context/infoAnimContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { CameraProvider } from "./context/cameraContext";
import { CategoryProvider } from "./context/selectedLensCategory";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import "./i18n";

export const msalInstance = new PublicClientApplication(msalConfig);

// msalInstance.initialize().then(() => {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   const accounts = msalInstance.getAllAccounts();
//   if (accounts.length > 0) {
//     msalInstance.setActiveAccount(accounts[0]);
//   } else {
//     msalInstance.loginPopup().then((response) => {
//       msalInstance.setActiveAccount(response.account);
//     });
//   }

//   msalInstance.addEventCallback((event: EventMessage) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//       const payload = event.payload as AuthenticationResult;
//       const account = payload.account;
//       msalInstance.setActiveAccount(account);
//     }
//   });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CameraProvider>
      <TechProvider>
        <CategoryProvider>
          <MaterialProvider>
            <CoatingProvider>
              <TreatmentProvider>
                <ColorProvider>
                  <InfoAnimProvider>
                    {/* {msalInstance.getAllAccounts().length > 0 && ( */}
                    <App pca={msalInstance} />
                    {/* )} */}
                  </InfoAnimProvider>
                </ColorProvider>
              </TreatmentProvider>
            </CoatingProvider>
          </MaterialProvider>
        </CategoryProvider>
      </TechProvider>
    </CameraProvider>
  </React.StrictMode>
);
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
