import React, { useEffect, useState, Suspense, useContext } from "react";
import {
  FormControl,
  Select,
  Card,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import {
  BackgroundENVModel,
  LensCategoryItem,
  LensModel,
} from "../../utils/commonInterfaces";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { LensModels } from "../../utils/lensModels";
import Typography from "@mui/material/Typography";
import Loader from "../../widgets/Loader";
import CompareGlbLoader from "../../widgets/CompareGlbLoader";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import HDRLoader from "../../widgets/HDRLoader";
import MeshWithGLBModel from "../../widgets/MeshWithGLBModel";
import { CameraProvider, CameraContext } from "../../context/cameraContext";
import BackgroundSelection from "../../widgets/BackgroundSelection";
import MonitorModel from "../../widgets/monitorModel";
import { useTranslation } from "react-i18next";

interface GroupedProps {
  categoryName: string;
  categoryItem: LensCategoryItem[];
}
interface CompareTechnologiesProps {
  defaultTech: string;
  availableOptions: LensCategoryItem[];
  onTechChange: (newTech: string) => void;
  filteredOptions: LensCategoryItem[];
  isBookObject: boolean;
  setIsBookObject: (isBookObject: boolean) => void;
  isMobileObject: boolean;
  setIsMobileObject: (isBookObject: boolean) => void;
  BackgroundENVModels: BackgroundENVModel[];
  ChnageBackgroundENV: (ENVName: string) => void;
  activeENV: string;
  BackgroundHDRModels: BackgroundENVModel;
  selectedCategory: string;
  defaultComapreModel: string;
}

const CompareTechnologies: React.FC<CompareTechnologiesProps> = ({
  defaultTech,
  availableOptions,
  filteredOptions,
  onTechChange,
  isBookObject,
  setIsBookObject,
  isMobileObject,
  setIsMobileObject,
  BackgroundENVModels,
  ChnageBackgroundENV,
  activeENV,
  BackgroundHDRModels,
  selectedCategory,
  defaultComapreModel,
}) => {
  const [selectedDevice, setSelectedDevice] = useState<string>("");
  const [modelPath, setModelPath] = useState<LensModel>();
  const [overallRating, setOverallRating] = useState<number>(0);
  const {
    cameraPosition,
    setCameraPosition,
    cameraQuaternion,
    setCameraQuaternion,
  } = useContext<any>(CameraContext);
  console.log("availableOptions", availableOptions);
  console.log("filteredOptions", filteredOptions);
  console.log("defaultTech", defaultTech);
  const { t } = useTranslation();
  const handleControlsChange = (e: any) => {
    setCameraPosition(e.target.object.position.clone());
    setCameraQuaternion(e.target.object.quaternion.clone());
  };

  const RatingFilter = (deviceName: string): number => {
    const filteredData = filteredOptions
      .filter((item) => item.displayName === deviceName)
      .flatMap((filteredItem) => filteredItem.data);

    const filledStars = filteredData.find(
      (item) => item?.key === "overallRating"
    )?.value;

    return filledStars && !isNaN(filledStars) ? filledStars : 0;
  };

  const handleDeviceChange = (event: SelectChangeEvent<string>) => {
    const deviceName = event.target.value as string;
    onTechChange(deviceName);
    updateModel(deviceName);
    setSelectedDevice(deviceName);
    setOverallRating(RatingFilter(deviceName));
  };

  const updateModel = (deviceName: string) => {
    const lensfilteredModels = LensModels.filter(
      (LensModel) =>
        LensModel?.techName?.toLowerCase() === deviceName.toLowerCase()
    );
    setModelPath(lensfilteredModels[0]);
  };

  useEffect(() => {
    updateModel(defaultTech);
    setOverallRating(RatingFilter(defaultTech));
    setSelectedDevice(defaultTech);
  }, []);

  useEffect(() => {
    if (defaultTech) {
      updateModel(defaultTech);
    }
  }, [defaultTech]);

  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    return (
      <div>
        {[...Array(fullStars)].map((_, index) => (
          <BsStarFill key={`full-${index}`} className="filled-star" />
        ))}
        {hasHalfStar && <BsStarHalf key="half" className="half-star" />}
      </div>
    );
  };

  return (
    <div className="tech-compare-layout">
      <FormControl className="tech-compare-layout__select-dropdown">
        <Select
          className="tech-compare-layout__select"
          onChange={handleDeviceChange}
          value={defaultTech}
        >
          <MenuItem
            className="tech-compare-layout__selected-menu-item"
            value={defaultTech}
          >
            <em>{t(`LensCategories.${defaultTech.replace(/\s+/g, "")}`)}</em>
          </MenuItem>
          {availableOptions.map((item) => (
            <MenuItem
              className="tech-compare-layout__menu-item"
              key={item.displayName}
              value={item.data?.[0]?.displayName || ""}
            >
              {t(`LensCategories.${item.displayName.replace(/\s+/g, "")}`)}
              {/* {item.displayName} */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedDevice && (
        <Card className="tech-compare-layout__card">
          {modelPath && (
            <div className="tech-compare-layout__canvas">
              <Canvas style={{ height: "350px" }}>
                <PerspectiveCamera
                  makeDefault
                  fov={60}
                  position={[-42, 0, 4]}
                />
                <ambientLight intensity={0.5} />
                <pointLight position={[10, 10, 10]} />
                <OrbitControls
                  enableZoom={false}
                  enableRotate={true}
                  minPolarAngle={Math.PI * 0.5}
                  maxPolarAngle={Math.PI * 0.5}
                  position={cameraPosition}
                  quaternion={cameraQuaternion}
                  onChange={handleControlsChange}
                />
                <Suspense fallback={<Loader theme="black_theme" />}>
                  <MonitorModel BackgroundENVModels={BackgroundHDRModels} />
                  <HDRLoader BackgroundHDRModels={BackgroundHDRModels} />
                  {/* <MeshWithGLBModel
                    isBookObject={isBookObject}
                    isMobileObject={isMobileObject}
                    LensModels={modelPath}
                  /> */}
                  <CompareGlbLoader LensModels={modelPath} />
                </Suspense>
              </Canvas>
            </div>
          )}
          <div className="tech-compare-layout__tittle-section">
            <Typography className="tech-compare-layout__heading" gutterBottom>
              {t(`LensCategories.${defaultTech.replace(/\s+/g, "")}`)}
            </Typography>
          </div>

          {/* <div>{renderStars(overallRating)}</div> */}
          {/* <div className="tech-compare-layout__price-section">
            <Typography className="tech-compare-layout__price-section-list">
              From ₹14499.00 to 19449.00
            </Typography>
          </div> */}

          <div className="tech-compare-layout_bg-selection-wrapper">
            <div className="tech-compare-layout_panoramas">
              {BackgroundENVModels.map((item, index) => (
                <button
                  type="button"
                  className={`tech-compare-layout_btn-env-list ${
                    activeENV === item.name ? "active-btn" : ""
                  }`}
                  key={index}
                  onClick={() => ChnageBackgroundENV(item.name)}
                  style={{
                    backgroundImage: `url(${item.imagePath})`,
                    backgroundSize: "cover",
                  }}
                ></button>
              ))}
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default CompareTechnologies;
