import { LensTechnogolyData } from "../../utils/commonInterfaces";
import { InfoAnimContext } from "../../context/infoAnimContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
interface TechProps {
  avTechs: LensTechnogolyData[];
}

const Technologies: React.FC<TechProps> = ({ avTechs }) => {
  const infoAnimContext = useContext(InfoAnimContext);
  const { t } = useTranslation();

  const handleAnimationChange = (item: LensTechnogolyData) => {
    if (item.key === "prTech" && item.value !== "") {
      const newPreTechData = {
        benefits: item.benefits!,
        displayName: item.displayName!,
        animationSource: item.animationSource!,
        addOn: true,
        title: item.displayName,
        backgroundImage: item.backgroundImg,
      };
      infoAnimContext.setSelectedInfoAnim([newPreTechData]);
    } else if (item.key === "ultimaTech" && item.value !== "") {
      const newUltimaTechData = {
        benefits: item.benefits!,
        displayName: item.displayName!,
        animationSource: item.animationSource!,
        addOn: true,
        title: item.displayName,
        backgroundImage: item.backgroundImg,
      };
      infoAnimContext.setSelectedInfoAnim([newUltimaTechData]);
    }
  };

  return (
    <div className="tech__tech-wrapper">
      {avTechs.map(
        (tech: LensTechnogolyData, index) =>
          tech.value !== "" && (
            <button
              type="button"
              key={index}
              className="btn btn-info tech_btn"
              onClick={() => handleAnimationChange(tech)}
            >
              {tech.displayName
                ? t(`prTech.${tech.displayName.replace(/\s+/g, "")}`)
                : ""}
            </button>
          )
      )}
    </div>
  );
};

export default Technologies;
