import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import React, { useRef, useEffect, useState } from "react";
import { BackgroundENVModel, LensModel } from "../../utils/commonInterfaces";

interface MonitorModelProps {
  BackgroundENVModels: BackgroundENVModel;
}

const MonitorModel: React.FC<MonitorModelProps> = ({ BackgroundENVModels }) => {
  const monitorTexture = useGLTF("/texture/Updated Monitor_1.glb");
  const { camera } = useThree();
  const meshRef = useRef<THREE.Mesh>(null);
  const [isMonitor, setIsMonitor] = useState(false);

  useEffect(() => {
    if (BackgroundENVModels.model === "Indoor_jpg_3") {
      setIsMonitor(true);
    } else {
      setIsMonitor(false);
    }
  }, [BackgroundENVModels]);

  useFrame(() => {
    if (camera && meshRef.current != null) {
      const applyTextureTransformations = (
        scale: number | null,
        translateY?: number,
        translateZ?: number,
        translateX?: number
      ) => {
        if (meshRef.current != null) {
          meshRef.current.position.set(58, 0, 0);

          if (scale !== null) {
            meshRef.current.scale.set(scale, scale, scale);
          }
          if (translateY) {
            meshRef.current.translateY(translateY);
          }
          if (translateZ) {
            meshRef.current.translateZ(translateZ);
          }
          if (translateX) {
            meshRef.current.translateX(translateX);
          }
        }
      };

      switch (BackgroundENVModels.model) {
        case "Indoor_jpg_3":
          applyTextureTransformations(1, -30, 1);
          break;
        default:
          break;
      }
    }
  });

  useEffect(() => {
    return () => {
      if (meshRef.current) {
        meshRef.current.geometry?.dispose();
        if (Array.isArray(meshRef.current.material)) {
          meshRef.current.material.forEach((mat) => mat.dispose());
        } else {
          meshRef.current.material?.dispose();
        }
      }
    };
  }, [monitorTexture]);

  return (
    <>
      {isMonitor && <primitive ref={meshRef} object={monitorTexture.scene} />}
    </>
  );
};

export default MonitorModel;
